<template>
    <b-modal 
        :id="`add-mark-modal-${id}`"
        centered
        title="Adicionar Marcador" 
        @show="onShow"
    >
        <validation-observer>
            <validation-provider
                #default="{ errors }"
                rules="required">
                <select
                    v-model="selectedMarkId"
                    class="flex-grow-1 form-control"
                    name="marcador"
                    :class="{ 'is-invalid': errors.length > 0 }"
                >
                    <option :value="null" selected> {{ marks.length === 0 ? 'Nenhuma opção encontrada' : 'Selecionar Marcador'}}</option>
                    <option v-for="mark in marks" :key="`mark-options-${mark._id}`" :value="mark._id">
                        {{ mark.name }}
                    </option>
                </select>
                <small v-if="errors.length > 0" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ ok, cancel }">
            <button @click="cancel()" class="btn btn-secondary">Cancel</button>
            <button @click="insertMark(ok)" class="btn btn-primary" :disabled="loading">{{ loading ? 'Adicionando...' : 'Adicionar'}}</button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {BModal} from "bootstrap-vue";
import api from '@/api'
export default {
    name: "AddMarkModal",

    component: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            required,
            marks: [],
            selectedMarkId: null,
            loading: false
        }
    },

    props: {
        id: {
            type: String,
            default: ''
        }
    },

    components: {
        BModal,
        ValidationProvider,
        ValidationObserver
    },

    methods: {
        onShow() {
            if (this.marks.length == 0)
                this.getMarks();
        },
        getMarks() {
            api.get('/marks')
                .then(response => {
                    const {type, body} = response.data;

                    if (type !== 'success') return;

                    body.map((mark) => this.marks.push(mark));
                });
        },

        insertMark(response) {
            if (!this.selectedMarkId) return;
            this.loading = true;
            const payload = {
                mark_id: this.selectedMarkId,
            }

            api.post(`/customers/add-mark/${this.id}`, payload)
            .then(response => {
                const {body, type} = response.data;

                if (type !== 'success') return;

                this.$emit('getMark', body);
            })
                .catch(() => {
                    this.$swal("Erro", "Houve um erro ao tentar adicionar marcador!", 'danger');
                })
            .finally(() => {
                response();
                this.$swal("Sucesso", "Marcador adicionado com sucesso!", 'success');
                this.loading = false;
                this.selectedMarkId = null;
            });
        }
    }
}
</script>

<style scoped>

</style>
