<template>
    <section id="dashboard-import-register" class="position-relative">
        <b-card no-body>
            <b-card-body class="p-5 body-grow d-flex">
                <loading v-if="loading.initial" />

                <section 
                    v-if="!loading.initial && imports == null" 
                    class="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center"
                >
                    <h2>Dados de importação não encontrados</h2>
                    <p>Os dados permanecem salvos por até 14 dias, passado esse tempo os dados de importação são removidos.</p>
                    <router-link
                        v-slot="{ navigate, href }"
                        :to="{ name: 'register' }"
                        custom
                    >
                        <a 
                            @click="navigate" 
                            :href="href" 
                            class="btn btn-primary"
                        >
                            Voltar
                        </a>
                    </router-link>
                </section>

                <section
                    v-if="!loading.initial && imports != null"
                    class="w-100 d-flex flex-column"
                >
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h3 class="text-left text-secondary font-weight-bolder">
                            Revisão
                        </h3>
                        <div class="d-flex align-items-center">
                            <span class="text-secondary">Total:</span>
                            <span
                                class="text-blue h4 font-weight-bold m-0 ml-2"
                            >
                                {{ pagination.total }}
                            </span>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-md-9">
                            <input
                                class="form-control"
                                placeholder="Buscar"
                                type="text"
                                v-model="search"
                            >
                        </div>
                        <div class="col-12 col-md-3">
                            <button @click="() => getImportedCustomers()" class="btn btn-primary w-100">
                                Filtrar
                            </button>
                        </div>
                    </div>

                    <section 
                        class="review-table my-4"
                        @scroll.passive="onScroll"
                    >
                        <table 
                            class="table table-borderless table-hover m-0" 
                            cellspacing="0"
                            width="100%"
                        >
                            <thead>
                                <th class="text-left">Nome</th>
                                <th>Telefone</th>
                                <th>Marcador</th>
                                <th>Processado em</th>
                                <th>Ação</th>
                            </thead>
                            <tbody
                                class="scrollable-table"
                            >
                                <tr v-if="imports == null || imports.length == 0">
                                    <td colspan="5">
                                        Nenhum resultado encontrado
                                    </td> 
                                </tr>
                                <tr
                                    v-for="customer in imports"
                                    :key="`row-imported-${customer._id}`"
                                    :class="{ 'bg-danger': !customer.success }"
                                >
                                    <td class="text-left">
                                        <p class="text-ellipsis m-0">
                                            {{ customer.name }}
                                        </p>
                                    </td>
                                    <td>
                                        <em 
                                            v-if="customer.success && customer.contact == null" 
                                            class="text-ellipsis m-0"
                                            v-b-tooltip="'Cliente não possui whatsapp'"
                                        >
                                            {{ customer.phone }}
                                        </em>
                                        <strong v-else class="text-ellipsis m-0">
                                            {{ customer.phone }}
                                        </strong>
                                    </td>
                                    <td>
                                        <span
                                            v-if="checkHasMark(customer.contact)"
                                            :style="{
                                                color: customer.contact.mark.color,
                                                fontWeight: 'bold',
                                            }"
                                        >
                                            {{ customer.contact.mark.name }}
                                        </span>
                                    </td>
                                    <td>
                                        <small>
                                            {{ moment(customer.created_at).format('DD/MM/YY HH:mm:ss') }}
                                        </small>
                                    </td>
                                    <td>
                                        <button
                                            v-if="customer.contact"
                                            class="btn btn-sm btn-secondary m-1"
                                            @click="addMark(`${customer.customer_id}`)"
                                        >
                                            {{ 
                                                customer.contact.mark == null
                                                ? "Adicionar Marcador"
                                                : "Alterar Marcador"
                                            }} 
                                        </button>
                                        <button
                                            v-if="customer.success"
                                            class="btn btn-sm btn-danger m-1"
                                            @click="handleDeleteCustomer(customer.customer_id)"
                                        >
                                            Remover
                                        </button>
                                        <button
                                            v-else
                                            class="btn btn-sm btn-warning m-1"
                                            @click="showError(customer)"
                                        >
                                            Ver erros
                                        </button>
                                    </td>
                                    <add-mark-modal
                                        :id="customer.customer_id"
                                        @getMark="getPayload"
                                    ></add-mark-modal>
                                </tr>
                            </tbody>
                        </table>
                        <span v-if="loading.more" class="spinner-border my-2" role="status"></span>
                    </section>

                    <div class="row align-items-center justify-content-end">
                        <div class="col-12 col-md-3 col-lg-2 d-flex mt-2">
                            <router-link
                                v-slot="{ navigate, href }"
                                :to="{ name: 'register' }"
                                custom
                            >
                                <a 
                                    @click="navigate" 
                                    :href="href" 
                                    class="btn flex-grow-1 btn-primary"
                                >
                                    Voltar
                                </a>
                            </router-link>
                        </div>
                    </div>
                </section>
            </b-card-body>
        </b-card>
        <color-picker-modal />
    </section>
</template>

<script>
import { BCard, BCardBody } from "bootstrap-vue";
import api from "@/api";
import { dateToMonthString } from "@/libs/utils";
import Loading from "@/views/components/Loading";
import AddMarkModal from "./AddMarkModal";
import ColorPickerModal from "@/views/pages/dashboard/messages/modals/AddMarkModal";
import moment from "moment";

export default {
    components: {
        BCard,
        BCardBody,
        Loading,
        AddMarkModal,
        ColorPickerModal,
    },
    data() {
        return {
            search: null,
            imports: null,
            pagination: null,
            loading: {
                initial: false,
                more: false
            },
            marks: [],
        };
    },
    created() {
        this.getImportedCustomers();
    },
    watch: {
        '$route.params.id'(val) {
            if (val != null)
                this.getImportedCustomers();
        }
    },
    methods: {
        moment,
        dateToMonthString,
        getImportedCustomers(page = 1) {
            const params = { page, search: this.search };
            const batchId = this.$route.params.id;

            if(page === 1)
                this.loading.initial = true;
            else
                this.loading.more = true;

            api.get(
                `/customers/imported/${batchId}`,
                { params }
            )
                .then((response) => {
                    const { type, message, body, misc } = response.data;
                    if (type !== "success")
                        this.$swal({ title: message, icon: type });

                    
                    if (page === 1) 
                        this.imports = body;
                    else
                        this.imports.push(...body);
                    
                    this.pagination = misc;
                })
                .catch((error) => {
                    let message = "Importação não encontrada e/ou expirada.";
                    if (error.response) message = error.response.data.message;

                    this.$swal("Houve um erro inesperado", message);
                })
                .finally(() => {
                    this.loading.more = false;
                    this.loading.initial = false;
                });
        },
        handleDeleteCustomer(id) {
            this.$swal({
                title: "Tem certeza?",
                text: "Deseja remover esse cliente?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Remover",
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/customers/${id}`);
                },
            }).then((result) => {
                if (
                    result.isConfirmed &&
                    result.value.data.type === "success"
                ) {
                    const index = this.imports.findIndex(
                        (customer) => customer.customer_id === id
                    );
                    if (index === -1) return;

                    this.imports.splice(index, 1);
                }
            });
        },
        getPayload(payload) {
            const index = this.imports.findIndex(
                (customer) => customer.customer_id === payload.customer_id
            );
            if (index === -1) return;

            Object.assign(
                this.imports[index].contact, 
                { mark: { name: payload.mark_name, color: payload.color } }
            );
        },
        onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (this.loading.more || !this.pagination)
                return;

            const page = this.pagination.current_page;

            if (scrollTop + clientHeight >= scrollHeight - 100 && page < this.pagination.last_page)
                this.getImportedCustomers(page + 1)
        },
        showError(customer) {
            this.$bvToast.toast(customer.error, {title: 'Erro de importação', variant: 'warning'});
        },
        addMark(id) {
            this.$bvModal.show(`add-mark-modal-${id}`);
        },
        checkHasMark(contact) { 
            return contact && contact.mark;
        },
    },
};
</script>

<style lang="scss">
.review-table { 
    overflow: auto; 
    height: 50vh; 

    thead th { 
        position: sticky; 
        top: 0; 
        z-index: 1; 
        background-color: white;
    }

    td {
        vertical-align: middle !important;
    }
}
</style>
